<template>
  <div>
    <div v-if="editing === false">
      <p
        class="cursor-pointer" 
        @click="onClickText"
      >
        <slot name="text">
          {{ text }}
        </slot>
      </p>
    </div>
    <div 
      v-else 
      class="edit-text"
    >
      <slot name="label" />
      <textarea
        ref="textarea"
        v-model="text"
        type="text"
        :maxlength="maxLength"
        rows="1"
        class="overflow-hidden"
        @keydown="handleKeyDown" 
        @keyup.enter="(e) => allowEnter ? null : onEnter(e)" 
        @input="onInput"
        @focus="autoResize"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref } from 'vue'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const props = defineProps({
  editing: {
    required: true,
    type: Boolean,
    default: false
  },
  maxLength: {
    required: false,
    type: [Number, null],
    default: null
  },
  allowEnter: {
    required: false,
    type: Boolean,
    default: false
  },
  allowClick: {
    required: false,
    type: Boolean,
    default: false
  }
})

const text = defineModel({
  required: true,
  type: String
})

const textarea = ref(null)

const emit = defineEmits(['validate', 'clicktxt'])

function handleKeyDown(event) {
  store.dispatch('ws/sendEvent', {
    event: event,
    params: {
      key: event.key
    }
  })

  if (props.allowEnter === false && event.key === 'Enter') {
    event.preventDefault()
  } else {
    autoResize(null)
  }
  store.dispatch('ws/collaborativeEventTreated')
}

function autoResize(event) {
  if (event != null){
    store.dispatch('ws/sendEvent', { event:event })
  }

  textarea.value.style.height = "auto"
  textarea.value.style.height = textarea.value.scrollHeight + 'px'
  if (event != null) {
    store.dispatch('ws/collaborativeEventTreated')
  }
}

function onBlur() {
  if (textarea.value === null) {
    return
  }

  textarea.value.style.height = 'auto'

  if (text.value != '') {
    textarea.value.style.height = textarea.value.scrollHeight - 7 + 'px' 
  }
}

function onInput(event) {
  CollaborativeUtils.onInput(store, event, text.value, false, () => {
    if (text.value == '') {
      onBlur()
    }
  })
}

function onEnter(event) {
  event.stopPropagation()
  event.preventDefault()
  store.dispatch('ws/sendEvent', { event: event, params: { key:'enter' }})
  emit('validate')
  store.dispatch('ws/collaborativeEventTreated')
}

function onClickText(event) {
  if (props.allowClick === false) {
    return
  }

  store.dispatch('ws/sendEvent', { event: event })
  emit('clicktxt')
  store.dispatch('ws/collaborativeEventTreated')
}

</script>

<style scoped>
p {
  white-space: pre-wrap;
}

textarea {
  border: ridge 2px;
  padding: 5px;
  margin: 5px;
  min-height: 15px;
  height: auto;
  background: transparent;
  color: var(--color-text);
}

.edit-text {
  display: flex;
  flex-direction: column;
}
</style>