//#################### patient.js
export const UPDATE_DATA_SOURCE_COMPOSITION = "UPDATE_DATA_SOURCE_COMPOSITION"
export const UPDATE_PATIENT_DATA = "UPDATE_PATIENT_DATA"
export const SET_PATIENT_NOTES = "SET_PATIENT_NOTES"
export const SET_PATIENT_VIEWS = "SET_PATIENT_VIEWS"
export const SET_FOLDER_ID = "SET_FOLDER_ID"
export const SET_SELECTED_PATIENT_OPTION = "SET_SELECTED_PATIENT_OPTION"
export const SET_ENTITY_FOLDER_PATHS = "SET_ENTITY_FOLDER_PATHS"

//#################### assembly.js
export const UPDATE_USER_ASSEMBLIES = "UPDATE_USER_ASSEMBLIES"

//#################### circle.js
export const UPDATE_CIRCLES = "UPDATE_CIRCLES"
export const INDEX_MAIN_CIRCLES="INDEX_MAIN_CIRCLES"
export const UPDATE_STATE_CIRCLE_MOVING = "UPDATE_STATE_CIRCLE_MOVING"
export const KEEP_PREVIOUS_CIRCLE = "KEEP_PREVIOUS_CIRCLE"
export const SET_UNIT_PER_CIRCLE = "SET_UNIT_PER_CIRCLE"
export const SET_OPTIMAL_YEAR_PER_CIRCLE = "SET_OPTIMAL_YEAR_PER_CIRCLE"
export const CHANGE_REP_CIRCLE_COLOR = "CHANGE_REP_CIRCLE_COLOR"
export const SET_PERIOD_UNIT = "SET_PERIOD_UNIT"
export const SET_TEMPORALITY_SELECTED_CIRCLE = "SET_TEMPORALITY_SELECTED_CIRCLE"
export const UPDATE_HIDE_INNER_CIRCLE= "UPDATE_HIDE_INNER_CIRCLE"
export const SET_RADIUS = "SET_RADIUS"
export const UPDATE_CIRCLE_FILTERS="UPDATE_CIRCLE_FILTERS"
export const SET_HOVERED_CIRCLE = "SET_HOVERED_CIRCLE"
export const SET_HOVERED_REP_CIRCLE = "SET_HOVERED_REP_CIRCLE"
export const SET_STATE_COMPONENT_WATCHERS = "SET_STATE_COMPONENT_WATCHERS"

//#################### event.js
export const UPDATE_DISPLAYED_EVENTS = "UPDATE_DISPLAYED_EVENTS"
export const CHANGE_STATE_MEMORIZED_EVENT = "CHANGE_STATE_MEMORIZED_EVENT"
export const ADD_SELECTED_EVENT = "ADD_SELECTED_EVENT"
export const REMOVE_SELECTED_EVENT = "REMOVE_SELECTED_EVENT"
export const SET_HOVERED_EVENT = "SET_HOVERED_EVENT"
export const SET_HOVERED_REF_EVENT = "SET_HOVERED_REF_EVENT"
export const SET_DISPLAY_EVENTS = "SET_DISPLAY_EVENTS"
export const SET_MEMORIZE_EVENTS = "SET_MEMORIZE_EVENTS"
export const UPDATE_TOOLTIP_POLAR_COORDINATE = "UPDATE_TOOLTIP_POLAR_COORDINATE"
export const SET_LIST_MEMORIZED_EVENTS_SORT = "SET_LIST_MEMORIZED_EVENTS_SORT"
export const DISPLAY_ALL_ACTIVE_CIRCLE_EVENT="DISPLAY_ALL_LAST_CIRCLE_EVENT"
export const TOOLTIPS_ADD_EVENTS="TOOLTIPS_ADD_EVENTS"
export const TOOLTIPS_REMOVE_EVENTS="TOOLTIPS_REMOVE_EVENTS"
export const HIDE_AND_SHOW_TOOLTIPS="HIDE_AND_SHOW_TOOLTIPS"
export const ORDER_TOOLTIPS="ORDER_TOOLTIPS"
export const UPDATE_LABEL_TO_DISPLAY="UPDATE_LABEL_TO_DISPLAY"
export const PUSH_LABEL_TO_DISPLAY="PUSH_LABEL_TO_DISPLAY"
export const SET_EDITING_EVENT = "SET_EDITING_EVENT"
export const UPDATE_LINKED_EVENTS="UPDATE_LINKED_EVENTS"

//#################### layout.js
export const SET_EDITING_TYPE = "SET_EDITING_TYPE"

//#################### layout.js
export const UPDATE_CENTERX = "UPDATE_CENTERX"
export const UPDATE_CENTERY = "UPDATE_CENTERY"
export const SET_CONTEXT_MENU_SIZE = "SET_CONTEXT_MENU_SIZE"
export const UPDATE_SCALE= "UPDATE_SCALE"
export const SET_DISPLAYED_TEMPORALITY_MENU = "SET_DISPLAYED_TEMPORALITY_MENU"
export const UPDATE_TEMPORALITY_MENU_POS = "UPDATE_TEMPORALITY_MENU_POS"
export const UPDATE_WINDOW_SIZE="UPDATE_WINDOW_SIZE"
export const SET_DISPLAYED_LINES = "SET_DISPLAYED_LINES"


//#################### score.js
export const SET_COEF_RADIUS_SCORE = "SET_COEF_RADIUS_SCORE"
export const UPDATE_SCORE_CIRCLE = "UPDATE_SCORE_CIRCLE"
export const SET_ID_SCORE= "SET_ID_SCORE"
export const UPDATE_SCORE_CHECKER = "UPDATE_SCORE_CHECKER"
export const DISPLAY_SCORE_TOOLTIP = "DISPLAY_SCORE_TOOLTIP"
export const HIDE_SCORE_TOOLTIP = "HIDE_SCORE_TOOLTIP"
export const CHANGE_DISPLAY_VALUE ="CHANGE_DISPLAY_VALUE"
export const UPDATE_EVENT_HOVERED = "UPDATE_EVENT_HOVERED"
export const DISABLE_SCORE = "DISABLE_SCORE"

//#################### ws.js
export const UPDATE_ROOM_ID = "UPDATE_ROOM_ID"
export const SET_STATE_MODAL_SESSION_JOIN = "SET_STATE_MODAL_SESSION_JOIN"
export const UPDATE_COLLABORATION_STATUS = "UPDATE_COLLABORATION_STATUS"
export const SET_DISPLAY_CURSOR = "SET_DISPLAY_CURSOR"
export const SET_STATE_MODAL_SESSION_CREATION = "SET_STATE_MODAL_SESSION_CREATION"
export const SET_VISIO_AUDIO_DEVICE = "SET_VISIO_AUDIO_DEVICE"
export const SET_VISIO_VIDEO_DEVICE = "SET_VISIO_VIDEO_DEVICE"
export const SET_VISIO_STATE = "SET_VISIO_STATE"
export const SET_ROOM_PARTICIPANTS = "SET_ROOM_PARTICIPANTS"

//#################### familyHistory.js
export const UPDATE_FAMILY_HISTORY_CIRCLE = "UPDATE_FAMILY_HISTORY_CIRCLE"
export const SET_DISPLAY_FAMILY_HISTORY_CIRCLE = "SET_DISPLAY_FAMILY_HISTORY_CIRCLE"
export const UPDATE_FAMILY_HISTORY_EVENTS = "UPDATE_FAMILY_HISTORY_EVENTS"

//#################### index.js
export const UPDATE_SECTIONS = "UPDATE_SECTIONS"
export const SET_ID_PATIENT = "SET_ID_PATIENT"
export const SET_SELECTED_PERIOD = "SET_SELECTED_PERIOD"
export const SET_LOADING_REPRESENTATION = "SET_LOADING_REPRESENTATION"
export const SET_INDEX_HISTORY_SECTIONS = "SET_INDEX_HISTORY_SECTIONS"
export const SET_DISPLAY_LABEL_SECTIONS = "SET_DISPLAY_LABEL_SECTIONS"
export const SET_HISTORY_SECTIONS = "SET_HISTORY_SECTIONS"
export const SET_DISPLAY_HISTORY_SECTIONS = "SET_DISPLAY_HISTORY_SECTIONS"
export const SET_HISTORY_SECTIONS_MENU_POS = "SET_HISTORY_SECTIONS_MENU_POS"
export const SET_TYPES_EVENTS_TO_DISPLAY = "SET_TYPES_EVENTS_TO_DISPLAY"
export const CHANGE_THEME = "CHANGE_THEME"
export const CREATE_BACK_UP="CREATE_BACK_UP"
export const IS_EDIT_MODE="IS_EDIT_MODE"
export const UPDATE_LABEL_FILTERS="UPDATE_LABEL_FILTERS"
export const SET_RATIO_REM_PX = "SET_RATIO_REM_PX"
export const SET_EYEDIAG_MODE = "SET_EYEDIAG_MODE"
export const ADD_PREVIOUS_MODE = "ADD_PREVIOUS_MODE"
export const REMOVE_PREVIOUS_MODE = "REMOVE_PREVIOUS_MODE"
export const SET_EVENTS_PODIUM = "SET_EVENTS_PODIUM"
export const SET_CURRENT_DISPLAYED_BORDER_LIST = "SET_CURRENT_DISPLAYED_BORDER_LIST"
export const SET_OLD_DISPLAYED_BORDER_LIST = "SET_OLD_DISPLAYED_BORDER_LIST"
export const OPEN_COLUMNS_MODAL="OPEN_COLUMN_MODAL"
export const SET_COLUMNS_FILE="SET_COLUMNS_FILE"
export const SET_AS_SECTION="SET_AS_SECTION"
export const SET_ERROR = "SET_ERROR"
export const SET_UNCLASSIFIED = "SET_UNCLASSIFIED"
export const SET_ABORT_CONTROLLER = "SET_ABORT_CONTROLLER"
export const SET_HIERARCHY = "SET_HIERARCHY"
export const SET_DATA_EXTENSION = "SET_DATA_EXTENSION"

//#################### associatedEvents.js
export const UPDATE_ASSOCIATED_EVENTS_CIRCLE = "UPDATE_ASSOCIATED_EVENTS_CIRCLE"
export const UPDATE_SRC_EVENT_ASSOCIATED = "UPDATE_SRC_EVENT_ASSOCIATED"
export const DISABLE_ASSOCIATED_EVENT_CIRCLE = "DISABLE_ASSOCIATED_EVENT_CIRCLE"

//#################### user.js
export const UPDATE_USER_DATA = "UPDATE_USER_DATA"
export const SET_LOGGED_IN = "SET_LOGGED_IN"
export const SET_TYPE_ENTITY_CONTEXT = "SET_TYPE_ENTITY_CONTEXT"
export const SET_AVAILABLE_TYPE_ENTITY_CONTEXT = "SET_AVAILABLE_TYPE_ENTITY_CONTEXT"

//#################### formEntry.js
export const SET_LOCKED_PIE = "SET_LOCKED_PIE"
export const SET_LOCKED_EVENT = "SET_LOCKED_EVENT"
export const SET_LOCKED_PIE_TO_UNLOCK = "SET_LOCKED_PIE_TO_UNLOCK"
export const SET_CONFIG_BOX_ARROW = "SET_CONFIG_BOX_ARROW"
export const SET_HOVERED_SAVE_BUTTON = "SET_HOVERED_SAVE_BUTTON"
export const SET_SAVING_FIELDS_EVENT = "SET_SAVING_FIELDS_EVENT"

//#################### view.js
export const ADD_VIEW = "ADD_VIEW"
export const SET_DELETE_MODE = "SET_DELETE_MODE"

//#################### BorderList.js
export const UPDATE_DISPLAYED_LIST = "UPDATE_DISPLAYED_LIST"

//#################### BorderList/topElem.js
export const UPDATE_SORT_TOP_LIST = "UPDATE_SORT_TOP_LIST"

//#################### Pie.js
export const ADD_HOVERED_SECTION = "ADD_HOVERED_SECTION"
export const REMOVE_HOVERED_SECTION = "REMOVE_HOVERED_SECTION"
export const RESET_HOVERED_SECTION = "RESET_HOVERED_SECTION"
export const ADD_SELECTED_SECTION = "ADD_SELECTED_SECTION"
export const REMOVE_SELECTED_SECTION = "REMOVE_SELECTED_SECTION"
export const RESET_SELECTED_SECTION = "RESET_SELECTED_SECTION"
export const SET_CUSTOM_SECTIONS_CONFIG = "SET_CUSTOM_SECTIONS_CONFIG"

//#################### diabete.js
export const SET_DIAB_EVENT = "SET_DIAB_EVENT"

//#################### record.js
export const SET_RECORD_STATUS = "SET_RECORD_STATUS"
export const SET_RECORD_EVENTS = "SET_RECORD_EVENTS"
export const SET_RECORD_INDEX_EVENT = "SET_RECORD_INDEX_EVENT"
export const SET_RECORD_SECONDS_ELAPSED = "SET_RECORD_SECONDS_ELAPSED"
export const SET_START_RECORD_DATE = "SET_START_RECORD_DATE"
export const SET_RECORD_REPLAY = "SET_RECORD_REPLAY"
export const SET_REPLAY_ROOM_ID = "SET_REPLAY_ROOM_ID"
export const SET_TIMEOUT_ID_REPLAY = "SET_TIMEOUT_ID_REPLAY"
export const SET_REPLAY_DURATION = "SET_REPLAY_DURATION"
export const SET_INTERVAL_ID_REPLAY = "SET_INTERVAL_ID_REPLAY"
export const SET_IS_PLAYING_REPLAY = "SET_IS_PLAYING_REPLAY"
export const SET_QUEUE_RECORD_EVENTS = "SET_QUEUE_RECORD_EVENTS"
export const ADD_ELEM_QUEUE_RECORD_EVENTS = "ADD_ELEM_QUEUE_RECORD_EVENTS"
export const SET_IS_RECORDING = "SET_IS_RECORDING"
export const SET_RECORD_CHUNK_INTERVAL = "SET_RECORD_CHUNK_INTERVAL"
export const SET_RECORD_ROOM_ID = "SET_RECORD_ROOM_ID"
export const SET_RECORDING_TYPE = "SET_RECORDING_TYPE"

//#################### replay.js
export const SET_DISPLAY_PLAYER_CONTROLS = "SET_DISPLAY_PLAYER_CONTROLS"

//#################### e2eTesting.js
export const E2E_SET_TEST_NAME = "E2E_SET_TEST_NAME"
export const E2E_SET_IDX_SCREENSHOT = "E2E_SET_IDX_SCREENSHOT"

//#################### appNavigation.js
export const SET_APP_ROUTE_NAME = "SET_APP_ROUTE_NAME"

//################### common.js
export const SET_MEMORIZED_EVENTS = "SET_MEMORIZED_EVENTS"
export const SET_EVENT_PROPERTIES = "SET_EVENT_PROPERTIES"

//################### tuto.js
export const SET_SELECTED_TUTO = "SET_SELECTED_TUTO"
export const SET_SELECTED_TUTO_PART = "SET_SELECTED_TUTO_PART"
export const SET_ID_DISPLAYED_TUTO = "SET_ID_DISPLAYED_TUTO"
export const SET_TUTO_PARTS = "SET_TUTO_PARTS"
export const SET_SPACE_TO_RECORD = "SET_SPACE_TO_RECORD"

//################### notification.js
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const REMOVE_NOTIFICATION = "DELETE_NOTIFICATION"

//################### report.js
export const SET_REPORTS = "SET_REPORTS"
export const SET_ID_SELECTED_REPORT = "SET_ID_SELECTED_REPORT"

//################### filtering.js
export const SET_FILTERING_HIGHLIGHT = "SET_FILTERING_HIGHLIGHT"
export const SET_FILTERING_STRICT_REPRESENTATION = "SET_FILTERING_STRICT_REPRESENTATION"
export const SET_SYMBOLS = "SET_SYMBOLS"
export const SET_EVENT_FILTERS = "SET_EVENT_FILTERS"
export const SET_FILTERED_EVENTS = "SET_FILTERED_EVENTS"

//################### parcours.js
export const SET_HOVERED_PARCOURS = "SET_HOVERED_PARCOURS"
export const SET_LOCKED_PARCOURS = "SET_LOCKED_PARCOURS"

//################### legend.js
export const SET_LOCKED_LEGEND_BY = "SET_LOCKED_LEGEND_BY"

//################### tips.js
export const SET_TIPS_NO_LONGER_DISPLAY = "SET_TIPS_NO_LONGER_DISPLAY"