<template>
  <div>
    <label>
      Plus petit que:
      <input
        v-model="data.upperBound"
        @input="(e) => onInput(e, data.upperBound, false)"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomFilteringFormSmallerThan'
}
</script>

<script setup>
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const data = defineModel({
  type: Object,
  required: true
})

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])
</script>
