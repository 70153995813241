import CircleUtility from "@/libraries/CircleUtility"
import loadingAnimation from '@/animation/loading.js'
import D3Animation from '@/config/D3Animation'
import Circles from "@/libraries/Circles"
import * as d3 from "d3"

import * as mutationTypes from '@/store/mutations-types.js'

/**
 * Cette classe permet la gestion de l'animation de recatégorisation
 */

class CategorisationAnimation {
  constructor() {
    /**
     * Il s'agit de la référence de l'instance du composant Circles
     * @type {ComponentCircles}
     */
    this.refCircleComponent = null
    /**
     * Permet de déterminer si l'animation est active
     * @type {Boolean}
     */
    this.active = false
    /**
     * Détermine si les données ont finit d'être transmise du serveur vers le client
     * @type {Boolean}
     */
    this.dataAvailable = false
    /**
     * Indique si l'animation de chargement est terminé
     * @type {Boolean}
     */
    this.endLoading = false
    /**
     * Permet de stocker la référence de la fonction permettant de revoquer le watcher qui surveille les modifications sur les cercles
     * @type {Function}
     */
    this.watchEnd = null
  }

  /**
   * Setter de la variable refCircleComponent
   * @param {ComponentCircles} refCircleComponent Référence de l'instance du composant Circles
   */
  setRefCircleComponent(refCircleComponent) {
    this.refCircleComponent = refCircleComponent
  }

  /**
   * Cette fonction permet le démarrage de l'animation de recatégorisation. Concrétement la partie de la transition où les piques des cercles disparaissent pour laisser place à des cercles parfait, puis le lancement de l'animation de chargement
   * @param {Store} store Référence du store vuex
   */
  async startAnimation(store) {
    if (!this.active) {
      store.commit(`circle/${mutationTypes.SET_STATE_COMPONENT_WATCHERS}`, false)
      this.active = true;
      this.watchEnd = store.watch(() => store.getters['circle/circles'], () => {
        //loadingAnimation.interruptAnimation()
        this.dataAvailable = true
      })

      let sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], false))
      sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])

      await Circles.displayCircles({
        paths: sectionsPath,
        interactions: false,
        animationTime: D3Animation.ANIMATION_CATEGORISATION_UNDISTORDED_CIRCLES,
        transition: (selection, animationTime) => { 
          Circles.currentTransition = selection.transition().duration(animationTime).attr("d", (d) => d.path)
        }
      })

      if (!this.dataAvailable) {
        await loadingAnimation.waiting(store)
      }

      this.endLoading = true;
      this.endLoadingAnimation(store)
    } else {
      this.endLoadingAnimation(store)
    }
  }

  /**
   * Cette fonction permet d'executer la fin de l'animation. C'est à dire le moment où les données sont disponibles et que les piques des nouveaux cercles apparaissent
   * @param {Store} store Référence du store vuex
   */
  async endLoadingAnimation(store) {
    if (this.dataAvailable && this.endLoading) {
      let sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], false))
      sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])

      //Le clonage puis la suppression après evite l'effet clignotement lorsque les données se rafraichisse. L'effet clignotement se produit car en fonction du nombre de catégories séléctionné il n'y a pas le même nombre de paths au final
      try {
        d3.select("#displayCircle")
          .clone(true)
          .attr('id', 'oldDisplayCircle')
  
        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: false,
          animationTime: 0,
        })
  
        d3.select("#oldDisplayCircle")
          .remove()
  
        sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], true))
        sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])
  
        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: true,
          animationTime: D3Animation.ANIMATION_CATEGORISATION_DISTORDED_CIRCLES,
        })
      } catch (err) {
        console.error(err)

        d3.select("#oldDisplayCircle").remove()
        sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], true))
        sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])
        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: true,
          animationTime: 0,
        })
      }

      this.active = false
      this.dataAvailable = false
      this.endLoading = false

      if (this.watchEnd !== null) {
        this.watchEnd()
        this.watchEnd = null
      }

      store.commit(`circle/${mutationTypes.SET_STATE_COMPONENT_WATCHERS}`, true)
      store.dispatch('circle/onCircleStopMove', null, {root: true})
      store.commit(mutationTypes.SET_LOADING_REPRESENTATION, false, { root: true })
    }
  }
}

export default new CategorisationAnimation()