<template>
  <div>
    <div
      v-if="currentComponentConfig?.contentComponent !== undefined"
      class="root-content-tutorial"
    >
      <component
        :is="currentComponentConfig.contentComponent"
        class="flex justify-center"
      />
    </div>
    <div>
      <Overlay
        :nb-components="nbComponents"
        :timeout="timeout"
        :display="currentComponentConfig?.component !== null"
        :start-index="currentIndex"
        :with-record="currentComponentConfig?.idTutoPartRecord !== undefined"
        :editable-text="editableOverlay"
        @change-component="changeComponent"
        @exit="onExitTutorial"
        @edit-text="onEditTextTuto"
      >
        <component
          :is="currentComponentConfig?.component"
          v-if="currentComponentConfig?.component !== undefined"
        />
        <EditableText
          v-else-if="currentComponentConfig?.Text !== undefined"
          v-model="currentComponentConfig.Text"
          :allow-enter="true"
          :editing="editTextTuto"
        />
      </Overlay>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialManager'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, computed, watch } from 'vue'
import Overlay from '@/components/tutorial/Overlay.vue'
import * as mutationTypes from '@/store/mutations-types.js'
import utils from '@/libraries/utils.js'
import EditableText from '@/components/utils/EditableText.vue'
import collaborativeEventDispatcher from '@/libraries/collaborative/CollaborativeEventDispatcher.js'

const store = useStore()
const router = useRouter()

const props = defineProps({
  allComponents: {
    type: Array,
    required: true
  },
  nextTutorial: {
    type: [Number, null],
    required: false,
    default: null
  }
})

const currentIndex = ref(0)
const currentComponentConfig = ref(null)
const editTextTuto = ref(false)
const nbComponents = ref(props.allComponents.length)
const timeout = ref(0)

let unwatch = null

const selectedTutoPart = computed(() => store.getters['tuto/selectedTutoPart'])
const isPlayingReplay = computed(() => store.getters['record/replay/isPlayingReplay'])
const editableOverlay = computed(() => currentComponentConfig.value !== null && currentComponentConfig.value.component === undefined)

onMounted(() => {
  let index = 0
  if (selectedTutoPart.value) {
    const res = props.allComponents.findIndex(c => c.idTutoPartRecord === selectedTutoPart.value)
    if (res >= 0) {
      index = res
    }
  }
  setTimeout(() => {
    changeComponent(index)
  }, 1000)
})

async function changeComponent(index) {
  currentIndex.value = index
  const item = props.allComponents[index]
  currentComponentConfig.value = item

  if (typeof unwatch === 'function') {
    unwatch()
  }
  await store.dispatch('record/replay/stopReplay')
  startAssociatedRecord()
  timeout.value = item.timeout ? item.timeout : 0
}

function startWatching() {
  unwatch = watch(isPlayingReplay, () => {
    if (isPlayingReplay.value === false) {
      unwatch()
      startAssociatedRecord()
    }
  })
}

function startAssociatedRecord() {
  const item = currentComponentConfig.value
  if (item.idTutoPartRecord) {
    collaborativeEventDispatcher.refStore = store
    collaborativeEventDispatcher.windowResize()
    startWatching()
    store.commit(`tuto/${mutationTypes.SET_SELECTED_TUTO_PART}`, item.idTutoPartRecord)
    utils.onGlobalAnimationEnd(store, () => {
      store.dispatch('tuto/launchTutoRecord', { idRecord: item.idTutoPartRecord })
    })
  }
}

async function onExitTutorial({ onCross }) {
  if (typeof unwatch === 'function') {
    unwatch()
  }
  await store.dispatch('record/replay/stopReplay')

  store.commit(`tuto/${mutationTypes.SET_SELECTED_TUTO}`, null)
  store.commit(`tuto/${mutationTypes.SET_SELECTED_TUTO_PART}`, null)
  store.commit(`tuto/${mutationTypes.SET_ID_DISPLAYED_TUTO}`, null)

  if (onCross === false && props.nextTutorial !== null) {
    store.dispatch('tuto/startTutoPatient', { idTuto: props.nextTutorial })
  } else {
    router.push({ name: 'Home' })
  }
}

function onEditTextTuto() {
  if (editTextTuto.value === true) {
    store.dispatch('tuto/updateTextTutoPart', {
      tutoPart: currentComponentConfig.value.IdTutoPart,
      text: currentComponentConfig.value.Text
    })
  }
  editTextTuto.value = !editTextTuto.value
}
</script>

<style scoped>

.root-content-tutorial {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: scroll;
}

.root-content-tutorial > div {
  border: 1px solid var(--color-shadow);
  padding: 15px;
  border-radius: 10px;
  width: 80%;
  height: 80vh;
}
</style>

<style>
.root-content-tutorial img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.root-content-tutorial video {
  max-width: 100%;
  max-height: 100%;
}
</style>