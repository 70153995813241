<template>
  <div>
    <ImportButton
      text="Importer"
      accept=".json"
      @content="onFileContent"
    />
    <p
      v-if="successImport"
      class="mt-2"
    >
      Importation réussi
    </p>
  </div>
</template>

<script setup>
import ImportButton from '@/components/utils/ImportButton.vue'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const options = defineModel({
  type: Object,
  required: true
})
const successImport = ref(false)
const wsSocket = computed(() => store.getters['ws/wsSocket'])
const isInCollaboration = computed(() => store.getters['ws/isInCollaboration'])
const isViewer = computed(() => store.getters['ws/isViewer'])

options.value.sections = []

onMounted(() => {
  wsSocket.value.on('collaborative:section:importCustom', collaborativeImportCustom)
})

onBeforeUnmount(() => {
  wsSocket.value.off('collaborative:section:importCustom', collaborativeImportCustom)
})

function collaborativeImportCustom({ customSections }) {
  if (isInCollaboration.value === true && isViewer.value === true) {
    options.value.sections = customSections
    successImport.value = true
  }
}

function onFileContent(content) {
  try {
    options.value.sections = JSON.parse(content)
    if (isInCollaboration.value === true && isViewer.value === false) {
      wsSocket.value.emit('collaborative:section:importCustom', {
        roomId: store.getters['ws/roomId'],
        customSections: options.value.sections
      })
    }
    successImport.value = true
  } catch {
    successImport.value = false
    options.value.sections = []
  }
}
</script>