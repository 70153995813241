<template>
  <div
    v-if="show"
    id="hud-tuto-recording"
  >
    <DesignButton
      text="Arrêter l'enregistrement (Echap)"
      @click="stopRecording"
    />
  </div>
</template>

<script>
export default {
  name: 'HudTutoRecording'
}
</script>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import TypeRecording from '@/shared/enums/typeRecording.js'
import DesignButton from '@/components/DesignButton.vue'
import * as mutationTypes from '@/store/mutations-types.js'
import * as d3 from 'd3'
import EyeFetch from '@/libraries/EyeFetch.js'

const store = useStore()

const selectedTuto = computed(() => store.getters['tuto/selectedTuto'])
const selectedTutoPart = computed(() => store.getters['tuto/selectedTutoPart'])
const typeRecording = computed(() => store.getters['record/record/typeRecording'])
const show = computed(() => typeRecording.value === TypeRecording.TUTO)
const spaceToRecord = computed(() => store.getters['tuto/spaceToRecord'])
const recordRoomId = computed(() => store.getters['record/record/recordRoomId'])

watch(spaceToRecord, () => {
  const body = d3.select(document.body)
  
  body.on('keyup.tutoRecord', spaceToRecord.value === true
    ? startRecording
    : null
  )
})

watch(show, () => {
  const body = d3.select(document.body)

  body.on('keyup.stopTutoRecording', show.value === true
    ? stopRecording
    : null
  )
})

watch(recordRoomId, async () => {
  if (recordRoomId.value !== '' && typeRecording.value === TypeRecording.TUTO) {
    let response = await EyeFetch(store,
      `${process.env.VUE_APP_SERVER_BASE_URL}/tuto/part/record`,
      {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({
          tutoPart: selectedTutoPart.value,
          idRecord: recordRoomId.value
        })
      }
    )

    if (!response.ok) {
      const notification = {
        id: Date.now(),
        type: 'error',
        message: 'Une erreur est survenue',
        timeout: 10 * 1000 // 10 secs
      }
      store.commit(`notification/${mutationTypes.ADD_NOTIFICATION}`, notification)
      store.dispatch('record/record/stopRecording') 
    }
  }
})

async function stopRecording(event) {
  if (event.code === 'Escape') {
    await store.dispatch('record/record/stopRecording')
    store.dispatch('tuto/startTutoPatient', { idTuto: selectedTuto.value})
  }
}

function startRecording(event) {
  if (event.code === 'Space') {
    store.commit(`tuto/${mutationTypes.SET_SPACE_TO_RECORD}`, false)
    store.dispatch('tuto/startRecordTutoRecord')
  }
}
</script>

<style scoped>
#hud-tuto-recording {
  position: absolute;
  top: 3%;
  right: 5%;
}
</style>