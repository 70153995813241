<template>
  <circle
    id="pie-origin"
    ref="pie-origin"
    cx="0"
    cy="0"
    :r="pieOriginRadius"
  />
  <g
    id="group-pie"
    ref="group-pie"
  />
  <!-- <PieExtension
    v-if="pieSelectedSections.length === 1"
    :selected-section="pieSelectedSections[0]"
    :radius="radius"
  /> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import * as mutationTypes from "@/store/mutations-types.js"
import * as d3 from "d3"
import reloadDataTypes from "@/shared/enums/reload_data_types.js"
import * as eyediagMode from "@/shared/enums/eyediagMode.js"
import D3Animation from "@/config/D3Animation.js"
import utils from "@/libraries/utils"
import CircleUtility from "@/libraries/CircleUtility.js"

// import PieExtension from '@/components/pie/PieExtension.vue'
import * as PieUtils from '@/components/pie/PieUtils.js'
import { pieLinkedSection } from '@/components/pie/PieLinkedSection.js'

export default {
  name: "PieSections",
  // components: {
  //   PieExtension
  // },
  setup() {
    const pieLinkedComposition = pieLinkedSection()

    return pieLinkedComposition
  },
  data: () => ({
    /**
     * Il s'agit du rayon du cercle au centre de la pie
     * @type {Number}
     */
    pieOriginRadius: 0,
    /**
     * Il s'agit du rayon externe de la pie
     * @type {Number}
     */
    radius: 0,
    /**
     * Détermine si le cercle au centre de la pie doit être affiché
     * @type {Boolean}
     */
    pieOriginDisplayed: false
  }),
  computed: {
    ...mapGetters({
      sections: "sections",
      mainRadius: "layout/radius",
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      referenceRadius: "layout/radius",
      mode: "eyediagMode",
      isChoseSectionModalOpen: "isChoseSectionModalOpen",
      eyediagMode: "eyediagMode",
      hierarchy: "hierarchy",
      pieSelectedSections: 'pie/pieSelectedSections',
      isLockedDataEntry: 'formEntry/isLocked',
      lockedPieDataEntry: 'formEntry/lockedPie',
      lockedPieToUnlock: 'formEntry/lockedPieToUnlock',
      events: 'event/common/events',
      isInNurseEntry: 'isInNurseEntry',
      labelToDisplay: "event/common/labelToDisplay",
      isEditMode: "isEditMode"
    }),
    historySections() {
      return this.$store.state.historySections;
    }
  },
  watch: {
    historySections(entries) {
      if (entries.length > 1) {
        this.handleCenterButton()
      }
    },
    mainRadius() {
      this.radius = this.mainRadius * 0.98
      this.pieOriginRadius = this.mainRadius * 0.08
      this.draw()
    },
    sections() {
      this.$store.commit('pie/RESET_SELECTED_SECTION')
      this.$store.commit('pie/RESET_HOVERED_SECTION')
      this.handleCenterButton()
      this.init()
    },
    //Appelé quand le cadenas est cliqué
    lockedPieToUnlock() {
      if (this.lockedPieToUnlock !== null) {
        this.pieMouseClick(null, {data: this.lockedPieToUnlock})
        this.pieMouseLeave(null, {data: this.lockedPieToUnlock})
        this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_PIE_TO_UNLOCK}`, null)
      }
    }
  },
  created() {
    this.pieOriginRadius = this.mainRadius * 0.08
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      getDataRepresentation: "circle/getDataRepresentation",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Permet l'intialisation de la pie en calculant le rayon extérieur de celle-ci et en appelant pour la création de la pie
     * @method
     * @public
     */
    init() {
      this.radius = this.mainRadius * 0.98;
      this.draw();
    },
    /**
     * Cette fonction permet d'activer le cercle bleu au centre de la représentation. Ainsi l'utilisateur peut naviguer à travers ses sélections. Cette fonctionnalité n'est pas active lorsque utilisateur n'a pas encore fait de sélection
     * @method
     * @public
     */
    initPieOrigin() {
      if (this.pieOriginDisplayed) {
        return
      }

      d3.select("#pie-origin").transition().duration(D3Animation.PIE_CIRCLE_ORIGIN_APPEAR)
      d3.select("#pie-origin")
        .on("click", this.changeSections)
        .on("mouseover", (event) => {
          this.sendEvent({ event: event })
          this.circleGrow()
          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event) => {
          this.sendEvent({ event: event })
          this.stopAnimation()
          this.collaborativeEventTreated()
        })
        .on("contextmenu", (event) => {
          this.sendEvent({ event: event })
          event.preventDefault();
          event.stopPropagation();
          this.$store.commit(
            mutationTypes.SET_DISPLAY_HISTORY_SECTIONS,
            !this.$store.state.displayHistorySections
          );
          if (event.isTrusted) {
            this.$store.commit(mutationTypes.SET_HISTORY_SECTIONS_MENU_POS, {
              x: event.clientX + 20,
              y: event.clientY + 20,
            });
          } else {
            const boundingRect =
              this.$refs["pie-origin"].getBoundingClientRect();
            this.$store.commit(mutationTypes.SET_HISTORY_SECTIONS_MENU_POS, {
              x: boundingRect.x + boundingRect.width / 2,
              y: boundingRect.y + boundingRect.height / 2,
            });
          }
          this.stopAnimation();
          this.collaborativeEventTreated()
        })
    },
    /**
     * Cette fonction permet de désactiver le cercle bleu au centre de l'écran. Il n'est plus nécessaire à l'utilisateur de se déplacer à travers ses sélections lorsque celui-ci n'en a plus qu'une seule.
     * @method
     * @public
     */
    terminatePieOrigin() {
      if (!this.pieOriginDisplayed) {
        return
      }
      //d3.select("#pie-origin").transition().duration(D3Animation.PIE_CIRCLE_ORIGIN_DISAPPEAR)
      d3.select("#pie-origin").on("click", null).on("contextmenu", null);
    },
    /**
     * Cette fonction permet de dessiner et afficher la pie à l'écran.
     * @method
     * @public
     */
    draw() {
      //Entrée de l'info diabète infirmière, permet de reset l'état de la pie après une entrée d'inforation
      this.$store.commit(`pie/${mutationTypes.RESET_SELECTED_SECTION}`)
      const pie = d3
        .pie()
        .value((d) => d)
        .padAngle(Math.PI / 200); //Pad angle valeur à l'oeil
      let data = pie(this.sections);
      data = data.map((datum, index) => ({
        ...datum,
        ...{
          startAngle: CircleUtility.degreesToRadians(this.sections[index].angle),
          endAngle: CircleUtility.degreesToRadians(this.sections[index].angle + this.sections[index].size)
        },
      }));
      /* eslint-disable */
      d3.select(this.$refs["group-pie"]).selectAll(".pie-quarter").remove();
      d3.select(this.$refs["group-pie"])
        .selectAll(".pie-quarter")
        .data(data)
        .enter()
        .append("path")
        .attr("d", (d) =>
          d.data.selectedByPie
            ? PieUtils.arcClick(d, this.radius)
            : PieUtils.arcStart(d, this.radius)
        )
        .attr("class", "pie-quarter")
        .style("fill-opacity", 0.3)
        .style("fill", PieUtils.getColor)
        .on("mouseover", this.pieMouseOver)
        .on("mouseleave touchend", this.pieMouseLeave)
        .on("click", this.pieMouseClick);
      /* eslint-enable */
    },
    /**
     * Cette fonction est appelée lors d'un survol d'une zone de la pie. Elle a pour effet de mettre en surbrillance la section sur laquelle le pointeur est positionné.
     * @method
     * @public
     * @param {Event} event Evenement fournit par le listener
     * @param {d} Object Détails sur la section de la pie survolée
     */
    pieMouseOver(event, d) {
      if (d.data.selectedByPie === false) {
        if (event !== null) {
          this.sendEvent({ event: event })
        }
        this.displayLinkedSections(d, this.radius)

        //A deporter dans une fonction à part spécifique Diabete
        //Affichage libellé des events
        if (this.isInNurseEntry && this.isLockedDataEntry === false && this.pieSelectedSections.length === 0) {
          const events = this.events.filter(event => event.Section === d.data.name && event.circleParent.active === true && event.disabled !== true)
          for (const e of events) {
            this.$store.commit(`event/common/${mutationTypes.PUSH_LABEL_TO_DISPLAY}`, e)
            this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, [...this.labelToDisplay])
          }
        }

        d3.select(this.$refs["group-pie"])
          .selectAll(".pie-quarter")
          .filter((datum) => datum.data.id === d.data.id)
          .style("fill-opacity", 0.3)
          .transition()
          .duration(D3Animation.PIE_MOUSE_OVER)
          .attr("d", d => PieUtils.arcEnd(d, this.radius))
          .style("fill", "url(#cyan-gradient)");

        if (this.historySections.length <= 1 && this.pieSelectedSections.length === 0) {
          this.initPieOrigin()
        }
        this.handleCenterButton();
        this.$store.commit(`pie/${mutationTypes.ADD_HOVERED_SECTION}`, [d.data])
        if (event !== null) {
          this.collaborativeEventTreated()
        }
      }
    },
    /**
     * Cette fonction est appelée lorsque le pointeur quitte une zone de la pie. Elle a pour effet de supprimer la surbrillance de la zone sur lequel le pointeur était si et seulement si la zone n'a pas été selectionné par l'utilisateur.
     * @method
     * @public
     */
    pieMouseLeave(event, d) {
      if (d.data.selectedByPie === false) {
        if (event !== null) {
          this.sendEvent({ event: event })
        }
        this.hideLinkedSections(d, this.radius)

        this.$store.commit(`pie/${mutationTypes.REMOVE_HOVERED_SECTION}`, [d.data])
        if (this.isInNurseEntry && this.isLockedDataEntry === false && this.pieSelectedSections.length === 0) {
          const events = this.events.filter(event => event.Section === d.data.name && event.circleParent.active === true)
          let labels = this.labelToDisplay
          for (const e of events) {
            labels = labels.filter(d => e.id !== d.id)
          }
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, labels)
        }

        d3.select(this.$refs["group-pie"])
          .selectAll(".pie-quarter")
          .filter((datum) => datum.data.id === d.data.id)
          .transition()
          .duration(D3Animation.PIE_MOUSE_LEAVE)
          .attr("d", d => PieUtils.arcStart(d, this.radius))
          .style("fill", PieUtils.getColor)
        if (this.historySections.length <= 1 && this.pieSelectedSections.length === 0) {
          this.terminatePieOrigin();
        }
        if (event !== null) {
          this.collaborativeEventTreated()
        }
      }
      this.handleCenterButton()
    },
    /**
     * Cette fonction est appelée lors d'un clique sur une zone de la pie. La fonction à pour effet d'activer ou désactiver la surbrillance permanente de la zone cliquée.
     * @method
     * @public
     */
    pieMouseClick(event, d) {
      if((this.isEditMode && this.pieSelectedSections.length == 1) == false){
        if (event !== null) {
          this.sendEvent({ event: event })
        }

        //Mode Nurse Entry et un événement est déjà lock
        if (this.isInNurseEntry && this.isLockedDataEntry && this.lockedPieDataEntry === null) {
          this.collaborativeEventTreated()
          return
        }

        this.pieMouseClickNurseEntry(event, d, this.lockedPieDataEntry)


        if (d.data.selectedByPie) {
          this.$store.commit(`pie/${mutationTypes.REMOVE_SELECTED_SECTION}`, [d.data])
          this.unSelectedLinkedSections(d, this.radius)
        } else {
          this.$store.commit(`pie/${mutationTypes.ADD_SELECTED_SECTION}`, [d.data])
          this.selectedLinkedSections(d, this.radius)
        }

        d.data.selectedByPie = !d.data.selectedByPie

        d3.select(this.$refs["group-pie"])
          .selectAll(".pie-quarter")
          .filter((datum) => datum.data.id === d.data.id)
          .style("fill-opacity", 0.3)
          .transition()
          .duration(D3Animation.PIE_MOUSE_CLICK)
          .attr("d", d => d.data.selectedByPie ? PieUtils.arcClick(d, this.radius) : PieUtils.arcEnd(d, this.radius))
          .style(
            "fill",
            d.data.selectedByPie
              ? "url(#aquam-gradient)"
              : "url(#cyan-gradient)"
          )

        this.handleCenterButton()

        if (this.pieSelectedSections.length > 0) {
          PieUtils.sparklePie()
        }

        if (event !== null) {
          this.collaborativeEventTreated()
        }
      }
    },
    pieMouseClickNurseEntry(event, d, lockedPieDataEntry) {
      if (this.isInNurseEntry) {
        //Clique sur une portion de la pie qui était déjà lock
        if (lockedPieDataEntry !== null && lockedPieDataEntry.id === d.data.id) {
          this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_PIE}`, null)
        } else {
          if (lockedPieDataEntry !== null) {
            const oldLockedPie = lockedPieDataEntry
            this.pieMouseClick(null, {data: oldLockedPie})
            this.pieMouseLeave(null, {data: oldLockedPie})
            this.$store.commit(`pie/${mutationTypes.ADD_HOVERED_SECTION}`, [d.data])
          }
          this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_PIE}`, d.data)
        }
      }
    },
    /**
     * Cette fonction permet de gérer le changement de section. Si des sections sont selectionnées la nouvelle représentation sera composée de leur sous sections. Si aucune section n'est séléctionné, la représentation sera composée de la précédent selections de sections
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit au listener
     */
    async changeSections(event) {
      //On ne doit pas pouvoir recatégoriser en entrée de l'information mais pouvoir revenir à la hiérarchy précédente
      if (this.isInNurseEntry && this.pieSelectedSections.length > 0) {
        return
      }

      this.sendEvent({ event: event })
      let isFileSeletionPossible = this.mode.type === eyediagMode.mode.UNIVERSAL && this.pieSelectedSections.length === 1

      // on ouvre la ChoseModalSection
      if (isFileSeletionPossible) {
        this.$store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`,!this.isChoseSectionModalOpen)
      } else {
        this.pieSelectedSections.forEach(s => {
          //TMP Pas top
          s.asSection = (s.content === undefined) ? undefined : s.content[0].hierarchy[0].type
        })
      }

      if (this.pieSelectedSections.length > 0) {
        this.$store.dispatch('addEntryHistorySections', {
          hierarchy: this.hierarchy,
          sections: this.pieSelectedSections
        })
      } else if (
        this.$store.state.historySections.length >= 2 &&
        this.$store.state.indexHistorySection > 0
      ) {
        this.$store.commit(mutationTypes.SET_INDEX_HISTORY_SECTIONS, this.$store.state.indexHistorySection - 1)
        this.$store.commit(mutationTypes.SET_HIERARCHY, this.$store.state.historySections[this.$store.state.indexHistorySection].hierarchy)
      } else {
        this.collaborativeEventTreated()
        return
      }

      this.$store.commit(`pie/${mutationTypes.RESET_SELECTED_SECTION}`)
      this.$store.commit(mutationTypes.SET_DISPLAY_HISTORY_SECTIONS, false);
      /**
       * on bypass getDataRespresentation parce que la representation va s'actualiser seulement 
       * si on clique sur le bouton "confirmer" de ChoseModalSection
       *
       */
      if (isFileSeletionPossible) {
        this.collaborativeEventTreated()
        return
      }

      await this.getDataRepresentation({
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION,
      });
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    /**
     * Cette fonction permet de déterminer en fonction de l'historique des recatégorisation et des sections de pie selectionné si le cercle au centre de la pie doit être affiché
     * @method
     * @public
     */
    handleCenterButton() {
      // Si au moins 1 element de la pie a été selectionné
      if (this.pieSelectedSections.length > 0){
        this.initPieOrigin()
        this.centerButtonOpacity(0.3)
        this.stopAnimation()
        this.pieOriginDisplayed = true
      }
      else if (this.historySections.length >= 2) { //Si l'utilisateur à déjà fait des recatégorisation
        this.initPieOrigin()
        this.centerButtonOpacity(0.1, true)
        this.stopAnimation()
        this.pieOriginDisplayed = true
      } else {
        this.centerButtonOpacity(0);
        this.stopAnimation();
        this.terminatePieOrigin();
        this.pieOriginDisplayed = false;
      }
    },
    /**
     * Permet de modifier l'opacité du cercle au centre de la pie
     * @method
     * @public
     * @param {Number} opacity Opacité à laquelle doit être la couleur de remplissage du cercle centrale de la pie
     * @param {Boolean} stroke Détermine si une couleur de contour doit-être appliqué sur le cercle au centre de la pie
     */
    centerButtonOpacity(opacity, stroke = false) {
      if(stroke){
        d3.select("#pie-origin")
          .style("fill-opacity", opacity)
          .style("stroke-opacity", 1)
          .style("stroke", "rgba(0, 228, 255)")
          .style("stroke-width", "1px")
        return
      }
      d3.select("#pie-origin")
        .style("fill-opacity", opacity)
        .style("stroke-width", "1px");
    },
    /**
     * Permet de faire grossir le cercle au centre de la pie
     * @method
     * @public
     */
    circleGrow() {
      const pieOriginRadius = this.getPieOriginRadius('max')
      d3.select("#pie-origin")
        .transition('pie-origin-grow')
        .duration(D3Animation.PIE_CIRCLE_ORIGIN_GROW)
        .attr("r", pieOriginRadius)
        .on("end", this.circleShrink);
    },
    /**
     * Permet de faire rétrécir le cercle au centre de la pie
     * @method
     * @public
     */
    circleShrink() {
      const pieOriginRadius = this.getPieOriginRadius('min')
      d3.select("#pie-origin")
        .transition('pie-origin-shrink')
        .duration(D3Animation.PIE_CIRCLE_ORIGIN_SHRINK)
        .attr("r", pieOriginRadius)
        .on("end", this.circleGrow);
    },
    /**
     * Permet de stopper l'animation du cercle au centre de la pie
     * @method
     * @public
     */
    stopAnimation() {
      const pieOriginRadius = this.getPieOriginRadius("min");
      d3.select("#pie-origin").interrupt('pie-origin-grow');
      d3.select("#pie-origin").interrupt('pie-origin-shrink');
      d3.select("#pie-origin").attr("r", pieOriginRadius);
    },
    /**
     * Permet d'obtenir le rayon du cercle au centre de la pie. Cette fonction sur surtout utilisé lors de l'animation de grossisemment puis retrécissement du cercle qui tourne en boucle
     * @method
     * @public
     * @param {String} size Taille demandée pour le rayon du cercle au centre de la pie
     * @values (min|max)
     */
    getPieOriginRadius(size) {
      if (size === "max") {
        return this.mainRadius * 0.09;
      } else {
        return this.mainRadius * 0.08;
      }
    }
  },
};
</script>

<style>
.pie-quarter {
  fill: transparent;
  opacity: 0.7;
}
#pie-origin {
  opacity: 0.3;
  fill: var(--color-hover-1);
}

</style>./Pie.vue/index.js