<template>
  <div
    ref="container"
    class="tutorielCase"
  >
    <div
      ref="tutoTitle"
      class="frontDisplay tutorielLine"
    >
      <slot name="title" />
    </div>

    <div
      ref="tutoDesc"
      class="frontDisplay tutorielLine"
    >
      <slot name="description" />
    </div>

    <div
      ref="tutoButton"
      class="frontDisplay tutorielLine buttonContainer"
    >
      <p style="color: grey; font-size: 10px">
        {{ numberSteps }} étapes
      </p>
      <button
        class="button"
        @click="(e) => onLaunch(e)"
      >
        Lancer
      </button>
    </div>
  </div>

  <div
    ref="tutoImgContainer"
    class="backDisplay"
  >
    <div
      ref="tutoImg"
      class="absolute"
    >
      <slot name="backgroundImage" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'

export default {
  name: "BaseTutoTile",
  props: {
    idTuto: {
      type: Number,
      required: true,
    },
    idPatient: {
      type: Number,
      required: true,
    },
    numberSteps: {
      type: Number,
      required: true
    },
    customHandler: {
      type: Boolean,
      default: false
    }
  },
  emits: ["launch"],
  data: () => ({
    resizeObserver: null
  }),
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.resizeImg()
    })

    this.resizeObserver.observe(this.$refs.container)
    this.resizeObserver.observe(this.$refs.tutoImg)

    this.resizeContainer()
  },
  beforeUnmount() {
    this.resizeObserver.disconnect()
  },
  methods: {
    ...mapActions({
      'sendEvent': 'ws/sendEvent'
    }),
    resizeImg() {
      const containerRight = this.$refs.container.getBoundingClientRect().right
      const imgWidth = this.$refs.tutoImg.getBoundingClientRect().width
  
      const titleBottom = this.$refs.tutoTitle.getBoundingClientRect().bottom
  
      this.$refs.tutoImgContainer.style.left = `${containerRight - imgWidth}px`
      this.$refs.tutoImgContainer.style.top  = `${titleBottom + window.scrollY}px`
    },
    resizeContainer() {
      const titleHeight  = this.$refs.tutoTitle.getBoundingClientRect().height
      const descHeight   = this.$refs.tutoDesc.getBoundingClientRect().height
      const buttonHeight = this.$refs.tutoButton.getBoundingClientRect().height
      const imgHeight    = this.$refs.tutoImg.getBoundingClientRect().height

      this.$refs.container.style.height = `${titleHeight + descHeight + buttonHeight + window.innerHeight * 0.02 + imgHeight}px`

      this.$refs.tutoDesc.style.lineHeight = '4vh'
    },
    async onLaunch(event) {
      this.sendEvent({event})
      this.resizeObserver.disconnect()

      if (this.customHandler) {
        this.$store.commit(`tuto/${mutationTypes.SET_SELECTED_TUTO}`, this.idTuto)
        this.$store.commit(`tuto/${mutationTypes.SET_ID_DISPLAYED_TUTO}`, this.idTuto)
        await this.$store.dispatch('tuto/getTutoParts')
        this.$emit('launch', event)
      } else {
        this.showPatient()
      }
    },
    async showPatient() {
      await this.$store.dispatch('tuto/startTutoPatient', { idTuto: this.idTuto })
    }
  }
}
</script>

<style>
.tutorielCase h1, .tutorielCase p {
  color: var(--c-black)
}
</style>

<style scoped>
.tutorielCase {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 2vh;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tutorielLine {
  padding-bottom: 0vh;
}

.frontDisplay {
    position: relative;
    z-index: 2;
}

.backDisplay {
    position: absolute;
    z-index: 1;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  align-items: center;
  background-color: #689acc;
  border: 0;
  border-radius: 10px;
  color: #201e1eea;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.button:hover, .button-18:focus { 
  background-color: #3e6596;
  color: #ffffff;
}

.button:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}
</style>