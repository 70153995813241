<template>
  <div
    id="root-eyediag"
    @contextmenu="onContextMenu"
  >
    <ContextMenu
      v-if="contextMenu.show"
      :x="contextMenu.x"
      :y="contextMenu.y"
      :items="contextMenu.items"
      :z-index="5"
      @close="closeContextMenu"
    />
    <ContextMenu
      v-if="$store.state.displayHistorySections"
      :x="$store.state.historySectionMenuPos.x"
      :y="$store.state.historySectionMenuPos.y"
      :items="listHistorySection"
      :z-index="5"
      @close="closeContextMenu"
    />
    <div id="representation-svg">
      <svg
        :viewBox="viewBox"
        style="position: absolute"
      >
        <g
          id="displayCircle"
          :style="`transform: translate(${centerX}px, ${centerY}px) scale(${scale})`"
        />
      </svg>
      <svg
        id="svg-root"
        :viewBox="viewBox"
        style="position: absolute"
      >
        <EyeDefs />
        <g
          class="wrapper"
          :style="`transform: translate(${centerX}px, ${centerY}px) scale(${scale})`"
          @mousedown="on_mousedown"
          @touchstart.passive="on_mousedown"
        >
          <Pie ref="pie" />
          <RepCircle />
          <Circles />
          <ScoreCircle />
          <FamilyHistoryCircle />
          <AssociatedEventsCircle />
          <Event ref="events" />
          <PointDebug
            v-if="debug.displayPoint"
            :only-current="debug.onlyCurrentCircle"
            :show-severity-all="debug.showSeverityAll"
          />
        </g>
      </svg>
    </div>
    <Sections
      :style="`transform: translate(${centerX}px, ${centerY}px) scale(${scale})`"
    />
    <div id="metadata">
      <Modals />
      <MainHud />
      <div v-if="displayOption">
        <button @click="$router.push({ name: 'Bio' })">
          Bio
        </button>
        <br>
        <button @click="debug.displayDebug = !debug.displayDebug">
          Affichage Debug
        </button>
        <br>
        <div v-if="debug.displayDebug">
          <button @click="debug.displayPoint = !debug.displayPoint">
            Debug Afficher Point
          </button>
          <button @click="debug.onlyCurrentCircle = !debug.onlyCurrentCircle">
            Debug Point Principal
          </button>
        </div>
      </div>
    </div>
    <EyeLabel :style="`transform: translate(${centerX}px, ${centerY}px) scale(${scale})`" />
    <Hud />
    <!-- <DeviceControls /> -->
    <Tooltip
      v-for="(tooltip) in tooltips"
      ref="tooltips"
      :key="tooltip.id"
      :tooltip-data="tooltip"
    />
    <State />
    <TemporalityMenu v-if="isDisplayedTemporalityMenu" />
    <ScoreTooltip v-if="isDisplayedScoreTooltip" />
    <BorderLists />
  </div>
</template>

<script>
import Tooltip from "@/components/tooltip/Tooltip.vue"
import ContextMenu from "@/components/ContextMenu.vue"
import ScoreCircle from "@/components/refCircle/ScoreCircle.vue"
import FamilyHistoryCircle from '@/components/refCircle/FamilyHistoryCircle.vue'
import RepCircle from "@/components/RepCircle.vue"
import Event from "@/components/event/Event.vue"
import PointDebug from "@/components/PointDebug.vue"
import TemporalityMenu from "@/components/TemporalityMenu.vue"
import { mapActions, mapGetters } from "vuex"
import Pie from "@/components/pie/Pie.vue"
import BorderLists from "@/components/borderLists/BorderLists.vue"
import Sections from "@/components/Sections.vue"
import Circles from "@/components/Circles.vue"
import * as mutationTypes from "@/store/mutations-types.js"
import * as d3 from "d3"
import contextMenuConfig from "@/config/contextMenu/contextMenu.js"
import menuTypes from "@/enums/menu_types.js"
import reloadDataTypes from "@/shared/enums/reload_data_types.js"
import proportion from "@/libraries/Proportion.js"
import CircleUtility from "@/libraries/CircleUtility.js"
import ScoreTooltip from "@/components/ScoreTooltip.vue"
import MainHud from "@/components/hud/main/MainHud.vue"
import EyeLabel from "@/components/Label.vue"
import EyeDefs from '@/components/defs/Defs.vue'
import D3Animation from '@/config/D3Animation.js'
import {resetContextMenu, applyConditionsSelectedItems} from "@/config/contextMenu/contextMenu.js"
import introAnimation from '@/animation/intro.js'
import State from '@/components/hud/state/State.vue'
import AssociatedEventsCircle from "@/components/refCircle/AssociatedEventsCircle.vue"
import * as Hierarchy from '@/shared/enums/hierarchy.js'
import Modals from '@/components/modal/Modals.vue'
import Hud from '@/components/hud/Hud.vue'
// import DeviceControls from '@/components/deviceControls/DeviceControls.vue'

import { useClientSpecificities } from '@/clientDeps/index.js'

export default {
  name: "EyeApp",
  components: {
    Tooltip,
    ScoreTooltip,
    ContextMenu,
    ScoreCircle,
    FamilyHistoryCircle,
    Event,
    Pie,
    Sections,
    RepCircle,
    Circles,
    TemporalityMenu,
    PointDebug,
    MainHud,
    EyeLabel,
    EyeDefs,
    BorderLists,
    AssociatedEventsCircle,
    State,
    Modals,
    Hud
    // DeviceControls
  },
  setup() {
    useClientSpecificities()
  },
  data: () => ({
    /**
     * Objet de configuration pour le menu contextuel principal de l'application
     * @type {EyeContextMenu}
     */
    contextMenu: {
      show: false,
      x: 0,
      y: 0,
      items: [],
    },
    /**
     * Objet de zoom retourné par d3.zoom
     * @type {d3.Zoom}
     */
    zoom: null,
    /**
     * Il s'agit de la viewBox - rectangle - définisant la position et la taile du svg
     * @type {String}
     */
    viewBox: `0 0 ${window.innerWidth} ${window.innerHeight}`,
    mode: "File",
    /**
     * Définit si les options de developpement doivent être affichés en dessous des informations du patient
     * @type {Boolean}
     */
    displayOption: false,
    /**
     * Options de debug disponibles
     * @type {Object}
     */
    debug: {
      displayDebug: false,
      showSeverityAll: false,
      displayPoint: false,
      onlyCurrentCircle: false
    }
  }),
  computed: {
    ...mapGetters({
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      scale: "layout/scale",
      isPlayingReplay: 'record/replay/isPlayingReplay',
      referenceRadius: "layout/radius",
      tooltips: "event/common/tooltips",
      isDisplayedScoreTooltip: "refCircle/score/isDisplayedScoreTooltip",
      isInCollaboration: "ws/isInCollaboration",
      isViewer: "ws/isViewer",
      isDisplayedList: 'borderList/isDisplayedList',
    }),
    /**
     * Indique si le menu de changement de temporalité depuis un cercle précis doit-être affichés
     * @type {Boolean}
     */
    isDisplayedTemporalityMenu() {
      return this.$store.state.layout.displayedTemporalityMenu;
    },
    /**
     * Elements constituant le menu contextuel du cercle bleu de la pie pour revenir à une précédente selection de catégorie
     * @type {EyeContextMenuItem[]}
     */
    listHistorySection() {
      return this.$store.state.historySections.map((section, index) => ({
        id: index,
        label: `${Hierarchy.hierarchyLabel[section.hierarchy.id] || section.hierarchy.id} -  Sélection ${index + 1}`,
        type: menuTypes.TEXT,
        selected: false,
        click: () => {
          this.$store.commit(mutationTypes.SET_DISPLAY_HISTORY_SECTIONS, false);
          this.$store.commit(mutationTypes.SET_INDEX_HISTORY_SECTIONS, index);
          this.$store.commit(mutationTypes.SET_HIERARCHY, section.hierarchy);
          this.getDataRepresentation({
            unitPerCircle: this.$store.state.circle.unitPerCircle,
            periodUnit: this.$store.state.circle.periodUnit,
            reloadDataTypes: reloadDataTypes.CATEGORISATION,
          });
        },
      }));
    },
  },
  watch: {
    isInCollaboration() {
      if (this.isInCollaboration && !this.$store.state.ws.viewer) {
        //Reset le zoom et le drag
        this.resizeWindow();
      }
    },
    isDisplayedList(newValue){
      this.onListSelectedEventChange(newValue)
    }
  },
  async created() {
    // Récuperation data depuis l'api
    resetContextMenu(this.$store)
    proportion.addMargin({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    })
    this.viewBox = `0 0 ${window.innerWidth} ${window.innerHeight}`;
    this.contextMenu.items = contextMenuConfig
  },
  async mounted() {
    document.addEventListener(
      "wheel",
      function touchHandler(e) {
        if (e.ctrlKey) {
          e.preventDefault();
        }
      },
      { passive: false }
    )
    this.setupZoom()
    window.addEventListener("resize", () => this.resizeWindow())
    //Permet de reset la position et le zoom par défaut pour éviter les corptements étrange après un chagement de patient (surtout utile pour les autres spectateurs en session collaborative)
    window.dispatchEvent(new window.Event('resize'))

    const promises = []
    await this.getPatientData()
    promises.push(
      this.loadDataRepresentation({
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: this.$store.getters['tuto/idDisplayedTuto'] === null ? reloadDataTypes.INTRO : reloadDataTypes.TEMPORALITY,
      })
    )
    promises.push(
      this.getListUserAssemblies()
    )
    await Promise.all(promises)
  },
  methods: {
    ...mapActions({
      getDataRepresentation: "circle/getDataRepresentation",
      sendEvent: "ws/sendEvent",
      updateLayout: "updateLayout",
      getPatientData: "patient/getPatientData",
      getListUserAssemblies: "assembly/getListUserAssemblies",
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Cette fonction est appelée lorsque l'utilisateur effectue des cliques sur la représentation. La fonction stop la propagation de l'événement pour éviter d'activer les options de drag / zoom à un moment non désiré
     * @param {Event} event Il s'agit de l'événement fournit par le listener
     */
    on_mousedown(event) {
      event.stopPropagation()
    },
    /**
     * Permet le chargement de nouvelles données depuis le serveur en fonction des paramètres fournit
     * @param {Object} params Paramètres à prendre en compte pour le chargement des nouvelles données
     */
    async loadDataRepresentation(params) {
      await this.getDataRepresentation(params);
    },
    /**
     * Permet d'initier les fonctionalités de déplacement et de zoom sur l'application
     */
    setupZoom() {
      if (this.zoom === null) {
        this.zoom = d3
          .zoom()
          .scaleExtent([1, 1.5])
          .constrain((transform, extent, translateExtent) => {
            /**
             * En session collaborative, les spectateurs ne peuvent pas bouger ou zoomer sur la représentation. Cependant si le présentateur laisse son click appuyé comme s'il allait
             * déplacer la représentation et que le spectateur bouge sa souris, la figure va se déplacer. Ce comportement est du au fait que l'event zoom start du présentateur est
             * envoyer aux spectateurs et que d3 en interne fait en sorte que lorsqu'un événement start à été reçu il ecoute sur window les événements de déplacement. La ligne suivante
             * permet dans cette situation d'obtenir l'actuelle transformation (translation + échelle) appliqué sur la représentation et de la retourner pour que la représentation du
             * spectateur reste inchangée
             */
            if (((this.isInCollaboration && this.isViewer) || (this.isPlayingReplay)) && (event && event.isTrusted)) {
              transform = d3.zoomTransform(d3.select('#svg-root').node())
              return transform
            } else {
              var dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0],
                dx1 = transform.invertX(extent[1][0]) - translateExtent[1][0],
                dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
                dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];
              return transform.translate(
                dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
                dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
              )
            }
          })
          .on("start", (event) => {
            if (event.sourceEvent) {
              this.sendEvent({
                event: event.sourceEvent,
                params: CircleUtility.eyePointRadial(
                  event.sourceEvent.clientX,
                  event.sourceEvent.clientY,
                  this.centerX,
                  this.centerY,
                  this.referenceRadius
                ),
              });
              this.collaborativeEventTreated()
            }
          })
          .on("zoom", (event) => {
            if (event.sourceEvent) {
              this.sendEvent({
                event: event.sourceEvent,
                params: {
                  ...CircleUtility.eyePointRadial(
                    event.sourceEvent.clientX,
                    event.sourceEvent.clientY,
                    this.centerX,
                    this.centerY,
                    this.referenceRadius
                  ),
                  ...{
                    deltaX: event.sourceEvent.deltaX,
                    deltaY: event.sourceEvent.deltaY,
                    deltaZ: event.sourceEvent.deltaZ,
                    deltaMode: event.sourceEvent.deltaMode,
                    ctrlKey: event.sourceEvent.ctrlKey,
                  },
                },
              })
              this.collaborativeEventTreated()
            }

            // Il est important que cette partie puisse s'executer même s'il n'y a pas d'événement source. Une translation ou zoom exécuté automatiquement par une fonction comme par exemple d3zoom.translateBy, ne génére pas d'événement source.
            const transform = event.transform.translate(
              window.innerWidth / 2,
              window.innerHeight / 2
            )
            const { x, y, k } = transform
            this.$store.commit(`layout/${mutationTypes.UPDATE_SCALE}`, k, {
              root: true,
            })
            this.$store.commit(`layout/${mutationTypes.UPDATE_CENTERX}`, x, {
              root: true,
            })
            this.$store.commit(`layout/${mutationTypes.UPDATE_CENTERY}`, y, {
              root: true,
            })
          })
          .on("end", (event) => {
            if (event.sourceEvent) {
              this.sendEvent({
                event: event.sourceEvent,
                params: CircleUtility.eyePointRadial(
                  event.sourceEvent.clientX,
                  event.sourceEvent.clientY,
                  this.centerX,
                  this.centerY,
                  this.referenceRadius
                ),
              });
              this.collaborativeEventTreated()
            }
          });

        d3.select("#svg-root").call(this.zoom).on("dblclick.zoom", null)
      }
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur demande l'affichage du menu contextuel principal de l'application
     * @param {Event} e Evenement fournit par le listener
     */
    onContextMenu(e) {
      //Cela permet d'ouvrir le menu contextuel normal lors de clique droit sur des input et pouvoir utiliser copier / coller
      if (e.target.localName === "input") {
        return;
      }
      this.sendEvent({
        event: e,
        params: CircleUtility.eyePointRadial(
          e.clientX,
          e.clientY,
          this.centerX,
          this.centerY,
          this.referenceRadius
        ),
      });
      e.preventDefault();
      if (this.contextMenu.show === true) {
        this.contextMenu.show = false;
      } else {
        this.contextMenu.x = e.clientX;
        this.contextMenu.y = e.clientY;
        applyConditionsSelectedItems(this.contextMenu.items, this.$store)
        this.contextMenu.show = true;
      }
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelé lorsque le panel à droite de l'application est s'ouvre ou se ferme. La fonction permet le déplacement de la représentation sur la gauche
     * @param {Boolean} isOpen Indique si le panel de droite est ouvert ou fermé
     */
    onListSelectedEventChange(isOpen) {
      const centerX = isOpen ? -100 : 100;
      const moveSelection = d3.select("#svg-root")
      const moveTransition = moveSelection.transition()
        .duration(D3Animation.EYEAPP_MOVE_REPRESENTATION)
        .on('interrupt', () => {
          //Si interrompu placer la représentation directement à la position finale
          this.zoom.translateBy(moveSelection, centerX, 0);
        })

      proportion.addMargin({
        top: 0,
        right: Math.sign(centerX) < 0 ? centerX * -1 : 0,
        bottom: 0,
        left: 0,
      });
      this.zoom.translateBy(moveTransition, centerX, 0);
    },
    /**
     * Cette fonction est appelé lorsque les proportions de la fenêtre sont modifiés pour réactualiser l'affichage de la représentation par rapport aux nouvelles proportions
     */
    async resizeWindow() {
      this.sendEvent({
        event: { type: 'resize' },
        window: true
      })
      this.viewBox = `0 0 ${window.innerWidth} ${window.innerHeight}`;
      d3.select("#svg-root").call(this.zoom.transform, d3.zoomIdentity);
      await this.updateLayout();
      introAnimation.interruptAnimation(this.$store)
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée pour la fermeture du menu contextuel principal de l'application
     * @param {Event} event Evenement fournit par le listener
     */
    closeContextMenu() {
      this.contextMenu.show = false;
      this.$store.commit(mutationTypes.SET_DISPLAY_HISTORY_SECTIONS, false);
    }
  },
}
</script>

<style>
#root-eyediag {
  @apply overflow-hidden;
  height: 100vh;
  width: 100vw;
}

#metadata {
  position: absolute;
  left: 40px;
  top: 10px;
  background-color: var(--color-bg-1);
  padding-inline: 10px;
}
</style>