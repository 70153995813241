import CircleUtility from "@/libraries/CircleUtility"
import circlesConfig from '@/config/circle'
import * as d3 from 'd3'
import * as mutationTypes from '../store/mutations-types.js'
import loadingAnimation from '@/animation/loading.js'
import D3Animation from '@/config/D3Animation.js'
import Circles from "@/libraries/Circles.js"

/**
 * Cette classe permet la gestion de l'animation de changement de temporalité depuis un cercle précis
 */

class TemporalityCircleAnimation {
  constructor() {
    /**
     * Permet de déterminer si l'animation est active
     * @type {Boolean}
     */
    this.active = false,
    /**
     * Permet de stocker la référence de la fonction permettant de revoquer le watcher qui surveille les modifications sur les cercles
     * @type {Function}
     */
    this.watchEnd = null,
    /**
     * Il s'agit de la référence de l'instance du composant Circles
     * @type {ComponentCircle}
     */
    this.refCircleComponent = null
    /**
     * Détermine si les données ont finit d'être transmise du serveur vers le client
     * @type {Boolean}
     */
    this.dataAvailable = false
  }

  /**
   * Setter de la variable refCircleComponent
   * @param {ComponentCircle} refCircleComponent Référence de l'instance du composant Circles
   */
  setRefCircleComponent(refCircleComponent) {
    this.refCircleComponent = refCircleComponent
  }

  /**
   * Permet l'initialisation de l'animation de changement de temporalité depuis un cercle
   * @param {Store} store Référence du store vuex
   */
  async startAnimation(store) {
    this.active = true
    store.commit(`circle/${mutationTypes.SET_STATE_COMPONENT_WATCHERS}`, false)
    this.watchEnd = store.watch(() => store.getters['circle/circles'], () => {
      loadingAnimation.interruptAnimation()
      this.dataAvailable = true
    })
    
    if (!this.dataAvailable) {
      await loadingAnimation.waiting(store)
    }
    this.endAnimation(store)
  }

  /**
   * Lance l'exécution de l'animation de changement de temporalité depuis un cercle
   * @param {Store} store Référence du store vuex
   */
  async endAnimation(store) {
    let previouslyActive = false

    if (store.state.circle.temporalityMenuSelectedCircle !== null) {
      const selectedCircleData = await store.dispatch('circle/findPreviousSelected', {
        selectedPeriod: store.state.circle.temporalityMenuSelectedCircle.period,
        periods: store.state.circle.circles,
        periodUnit: store.state.circle.periodUnit,
        unitPerCircle: store.state.circle.unitPerCircle
      }, {root: true})

      if (this.watchEnd !== null) {
        this.watchEnd()
        this.watchEnd = null
      }

      let sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], true))
      sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])

      try {
        await d3.select('#displayCircle')
          .selectAll(".circle")
          .transition()
          .duration(D3Animation.ANIMATION_TEMPORALITY_FROM_CIRCLE_CIRCLES_DISAPPEAR)
          .style('opacity', 0)
          .end()

        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: false,
          animationTime: 0,
          transition: (selection) => selection.style('opacity', 0)
            .attr("d", (d) => d.path)
            .style("fill", "none")
            .style("stroke", (d) => circlesConfig[d.configIndexBounded].color)
            .style("stroke-width", (d) => circlesConfig[d.configIndexBounded].stroke)
        })
        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: true,
          animationTime: D3Animation.ANIMATION_TEMPORALITY_FROM_CIRCLE_CIRCLES_APPEAR,
        })
      } catch (err) {
        Circles.displayCircles({
          paths: sectionsPath,
          interactions: true,
          animationTime: 0,
        })
        console.log(err)
      }

      this.active = false
      this.dataAvailable = false
      store.commit(`circle/${mutationTypes.SET_STATE_COMPONENT_WATCHERS}`, true)
      previouslyActive = selectedCircleData.active
      store.dispatch('circle/changeMainCircle', {selectedCircleData}, {root: true})
      store.commit(`circle/${mutationTypes.SET_TEMPORALITY_SELECTED_CIRCLE}`, null, {root: true})
      //Si l'on est déjà positionné sur le bon cercle donc seul un changement de temporalité se fait. ChangeMainCircle return dès le debut de la fonction car pas de déplacement à faire, cela induit que onCircleStopMove n'est pas appelé dans le composant Circles, il faut donc l'appeler manuellement pour réafficher les filtrages
      if (previouslyActive) {
        store.dispatch('circle/onCircleStopMove', null, {root: true})
      }
      store.commit(mutationTypes.SET_LOADING_REPRESENTATION, false, { root: true })
    }
  }
}

export default new TemporalityCircleAnimation()